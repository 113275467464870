<template>
  <div class="card card-custom">
    <div class="card-header py-3">
      <div class="card-title w-100 flex-row">
        <div class="w-50">
          <h3 class="card-label font-weight-bolder text-dark w-100">
            <div>{{ title }}</div>
          </h3>
          <span class="text-muted font-weight-bold font-size-sm mt-1">{{
            description
          }}</span>
        </div>
        <div class="w-50">
          <b-button
            v-if="$route.name === 'category.list'"
            variant="danger"
            class="float-right"
            @click="$router.push({ name: 'category.create' })"
            >NOVO</b-button
          >
        </div>
      </div>
    </div>
    <div class="card-body">
      <CategoryList v-if="$route.name === 'category.list'" />
      <CategoryForm
        v-if="
          $route.name === 'category.create' || $route.name === 'category.edit'
        "
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'CategoryPageList',

  computed: {
    title() {
      if (this.$route.name === 'category.create') {
        return 'Cadastrar família de produtos'
      }
      return 'Produtos'
    },
    description() {
      if (this.$route.name === 'category.list') {
        return 'Família de produtos cadastradas'
      }
      return 'Cadastre uma nova família de produto.'
    }
  }
}
</script>
